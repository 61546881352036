.groupcreate-container .input-group input {
  background: transparent;
  border: 0;
  border-bottom: 3px solid #234f9c;
  border-radius: 0;
  color: #fff;
  text-align: center;
  margin: 1rem;
  font-weight: 500;
}

.groups-avatar {
  width: 100%;
  display: flex;
  height:100px;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.groups-img-avatar {
  border-radius: .25rem;
  width: 100px;
  background: #fff;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
}

.groupcreate-container .input-group input:focus {
  outline: 0;
  box-shadow: none;
  border-bottom: 3px solid #234f9c9a;
}

.groupcreate-container .create-save {
  font-weight: 500;
  background: #4CAF50;
  border-radius: 2px;
  width: 90%;
}

.groups-avatar .groups-avatar__randomize {
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  bottom: -10px;
  background: #607D8B;
  border-radius: 50%;
  padding: 0.5rem;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.groups-avatar:active img {
  opacity: 0.6
}

.groups-avatar:active .groups-avatar__randomize {
  -moz-transform: scale(.8);
  -webkit-transform: scale(.8);
  -o-transform: scale(.8);
  -ms-transform: scale(.8);
  transform: scale(.8);
}