.widget-default.widget-streak {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}

.widget-default.widget-streak .widget-streak__special-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.widget-default.widget-streak .widget-streak__special-title h3 {
    font-size: 1rem;
    margin: 0;
    padding: .8rem 0;
    text-align: left;
    display: block;
}

.widget-default.widget-streak h3 img {
    width: 20px;
    margin: 0 .5rem;
}

.widget-default.widget-streak .widget-streak__container {
    padding: .5rem
}

.widget-default.widget-streak .widget-streak__team {
    padding-right: 0;
    padding-left: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.widget-default.widget-streak .widget-streak__team img {
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto .5rem;
}

.widget-default.widget-streak .widget-streak__team small {
    display: block;
    text-transform: uppercase;
    font-size: .7rem;
    font-weight: 700;
}

.widget-default.widget-streak .widget-streak__team span {
    font-weight: 700;
    opacity: .3;
    margin-bottom: .2rem;
    text-transform: uppercase;
    font-size: .7rem;
}

.widget-default.widget-streak .widget-streak__team h6 {
    margin: 0 auto;
    border-radius: 5px;
    font-size: .9rem;
    font-weight: 500;
    position: relative;
    padding: .1rem .3rem;
}

.widget-default.widget-streak .widget-streak__team.result_P h6 {
    background-color: rgba(255, 0, 0, 0.32);
}

.widget-default.widget-streak .positive-icon {
    color: limegreen
}

.widget-default.widget-streak .negative-icon {
    color: red
}

.widget-default.widget-streak .equal-icon {
    color: rgb(255, 196, 0);
}

.widget-default.widget-streak .widget-streak__team.result_E h6 {
    background-color: rgba(255, 196, 0, 0.39)
}

.widget-default.widget-streak .widget-streak__team.result_G h6 {
    background-color: #4caf5063
}

.widget-default.widget-streak .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #2196F3;
    background-color: #2196F3;
}

.widget-streak__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.widget-streak__row .row {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 0.3rem 0
}

.widget-streak__row .row .col-12{
    display: flex;
    width: 100%;
    padding:0.2rem 0.5rem;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}

.widget-streak__row .row .col-12 small{
    width:70px;
    font-weight: 500;
    text-align: left;
}


.widget-streak__row .row .col-12 img{
    width:25px;
    margin:0;
}

.widget-streak__row .row .col-12 span{
    font-size: .9rem;
    margin:0; 
    background-color: #00000070;
    padding:0.2rem 0.5rem;
    border-radius: 5px;
    font-weight: 500;
}