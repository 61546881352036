.gridlist_container {
  margin: 1rem 0;
}

.gridlist_container h6 {
  text-align: center;
}

.user-groups button {
  width: 100%;
  border: 0;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  background-color: #292929;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
}

.user-groups button:hover, .user-groups button:not(:disabled):not(.disabled):active {
  background: transparent;
  background-color: transparent;
  outline: none;
}

.user-groups .user-groups__meta {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
}

.user-groups .user-groups__info {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0;
  flex-wrap: wrap;
  border-top: 1px solid #5e5e5e56;
}

.user-groups .user-groups__info span {
  font-size: .9rem;
  text-align: left;
  font-weight: 500;
  color: #cccccc98;
}

.user-groups.user-groups__invitation button.user-groups__invitation-sent.btn-secondary {
  background-color: #4CAF50;
  opacity: 1;
}

.user-groups.user-groups__invitation button.user-groups__invitation-sent .user-groups__meta h6 span, .user-groups.user-groups__invitation button.user-groups__invitation-sent .user-groups__info span {
  color: #fff
}

.user-groups.user-groups__invitation .user-groups__info .user-groups__info-pending {
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 0.5rem;
}

.user-groups .user-groups__meta img {
  width: 40px;
  height: 40px;
  background: #111;
  border-radius: 50%;
  margin: 0 0.5rem;
}

.user-groups .user-groups__meta h6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin: 0
}

.user-groups__meta h6 span {
  font-size: .8rem;
  text-align: left;
  text-transform: none;
  color: #cccccc7a;
  padding: 0.2rem 0;
}

.user-groups .user-groups__meta svg {
  width: 50px;
}

.user-groups.user-groups__remove button {
  background: #607d8b1c;
  opacity: 1;
}

.user-groups.user-groups__remove button svg{
  fill:red;
}