.widget-default.widget-teamstreak {
  background: url('https://d3qiofd49j60uf.cloudfront.net/fpd_prode/general/default-stadium.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.widget-default.widget-teamstreak:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
}

.widget-default.widget-teamstreak .row {
  width: 100%;
  margin-left: 0;
  align-items: center;
  padding: .5rem 0;
}

.widget-default.widget-teamstreak .widget-teamstreak__local,
.widget-default.widget-teamstreak .widget-teamstreak__result,
.widget-default.widget-teamstreak .widget-teamstreak__away{
  text-align: center;
}

.widget-default.widget-teamstreak img {
  width: 32px;
}

.widget-default.widget-teamstreak h6 {
  font-weight: bold;
  font-size: .9rem;
  margin-top: .5rem;
  display: block;
}

.widget-default.widget-teamstreak small {
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
}

.widget-default.widget-teamstreak span {
  font-weight: 700;
  font-size: .9rem;
  display: block;
}

.widget-default.widget-teamstreak span svg {
  margin-bottom: 3px
}

.widget-default.widget-teamstreak .bad_shape svg {
  color: red;
}

.widget-default.widget-teamstreak .good_shape svg {
  color: limegreen;
}

.widget-default.widget-teamstreak .same_shape svg {
  color: rgb(255, 196, 0);
}
.widget-matchpredict__penalties{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}
.widget-matchpredict__step{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}