.container.outstanding {
  padding-top: 8rem;
  margin-top: 0;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  background-size: contain;
}

.outstanding-quantity {
  font-weight: 500;
  padding: 0.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.outstanding_message {
  position: relative;
}

.outstanding-quantity svg {
  margin-right: 0.5rem;
}

.container.outstanding span.background-effect {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -3rem;
  left: 0;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top center;
}

.container.outstanding span.background-effect:before {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+60,000000+100&0+0,1+60,0+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
  /* IE6-9 */
  content: '';
  display: block;
  width: 100%;
  height: 100%;
}

.container.outstanding h3 {
  z-index: 1;
  position: relative;
  text-align: center;
  font-size: 160%;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 1);
}

.container.outstanding .outstanding-ending__message {
  background: #f44336ab;
  border-radius: 5px;
  text-align: center;
  padding: 0.5rem;
  font-weight: 500;
}

.outstanding-terms{
  text-align: center;
}
.container.outstanding .outstanding_message {
  margin-top:1rem;
  text-align: center;
}
/* .container.outstanding .outstanding_message {
  width: 100%;
  height: 100%;
  background: #000000e0;
  position: absolute;
  top: -49px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
} */

.container.outstanding .outstanding-description{
  position: relative;
  text-align: center;
  font-size: 1.2rem;
}
.button_outstanding{
  font-weight: 500;
  text-transform: uppercase;
}
/* .terminos {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 15px;
  margin: 20px 0;
  background-color: white;
  color: black;
  text-align: justify;
}

.button_outstanding{
  width: 100%;
  margin: 10px auto;
}

.outstanding_message{
  padding: 20px;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
} */