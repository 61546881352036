/* .navbar_container{
	display: none;
} */

.form-container {
	padding:1rem;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
}

.button.button_create_active{
	background-color: green;
  background-image: none;
}

.nav-tabs{
	justify-content: center;
	border-bottom:0;
	margin-bottom:1rem;
}

.nav-tabs .nav-link, .nav-tabs .nav-item .nav-link {
	background-color: transparent;
	border:0;
	padding: 0.5rem 1rem;
	color: #fff;
	font-weight:500;
	opacity:.6
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item.show .nav-link:focus, .nav-tabs .nav-link.active:focus {
	opacity: 1;
	color:#fff;
	border-color: #dee2e6 #dee2e6 #fff;
	border-bottom: 3px solid;
}

.login-form__signin {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.login-form__signin p{
	color:#fff;
	font-weight: bold;
}

.login-form__signin .gmail,
.login-form__signin .facebook{
    width: 80%;
	margin-bottom:1rem
}

.login-form__signin .gmail a,
.login-form__signin .facebook a{
	background: #4285f4;
    display: block;
    padding: 0.2rem;
    border-radius: 2px;
    color: #fff;
	font-weight: 500;
	font-size: 90%;
}
.login-form__signin .gmail a:hover,
.login-form__signin .facebook a:hover{
	text-decoration:none;
}
.login-form__signin .facebook a{
	background:#1777f2
}
.login-form__signin .gmail a svg,
.login-form__signin .facebook a svg{
    width: 50px;
    margin-right: 0.5rem;
}
.login-form__signin .facebook a svg{
	padding:0.5rem;
}

.login-form__account, .signup-form{
	display: flex;
    flex-direction: column;
	padding: 1rem 0;
	padding-bottom:0;
	justify-content: center;
	align-items: center;
}

.login-form__account p:first-child{
	color:#fff;
	text-align: center;
	font-weight: bold;
}

.login-form__account .login-form__email input,
.signup-form .signup-form__nombre input,
.signup-form .signup-form__usuario input,
.signup-form .signup-form__email input,
.signup-form .signup-form__password input,
.login-form__account .login-form__password input{
	border-radius: 2px;
	border-top-left-radius:2px;
	border-bottom-left-radius:2px;
	border:0;
	margin-bottom:0.9rem;
}


.login-form__account .login-form__email,
.login-form__account .login-form__password,
.login-form__account .login-form__checkbox{
	width:80%;
}

.login-form__account .login-form__checkbox{
	color:#fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.login-form__account .login-form__checkbox .form-check{
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: space-around;
}

.login-form__account .login-form__checkbox .form-check input{
    margin: 0;
    position: relative;
    margin-right: 3px;
}

.login-form__account .login-form__checkbox label,
.login-form__account .login-form__checkbox p{
	font-size: .9rem;
	margin: 0;
}

.login-form__account-login{
	display: flex;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
}

.login-form__account-login .button-login{
	margin: 1rem 0;
    border-radius: 2px;
    background: #4CAF50;
	font-weight: 500;
	border:0;
	order:1;
	padding: .7rem 2rem;
}
.login-form__account-login .button-signup.button-disabled,
.login-form__account-login .button-login.button-disabled{
	order:2;
	background: none;
	color: #2196F3;
}
.login-form__account-login .button-signup.button-active,
.login-form__account-login .button-login.button-active{
	order:1;
	background: #4CAF50;
	padding: .7rem 2rem;
	color:#fff;
}

.login-form__account-login .button-signup:active,
.login-form__account-login .button-signup:focus,
.login-form__account-login .button-login:active,
.login-form__account-login .button-login:focus
{
	outline:0;
	box-shadow:none;
}
.login-form__account-login .button-signup{
	margin: 0;
    background: none;
    color: #2196F3;
	font-weight: 500;
	border:0;
	order:2
}

.signup-form form{
	width: 80%;
}

.login-form__info{
    font-size: 0.9rem;
    display: block;
		margin-top: 1rem;
		text-align:center;
		font-weight: 500;
		color:red;
}

@media (min-width: 767px) {
	.form-container {
		max-width:500px;
		margin:0 auto;
	}
}
.alert{
	position: absolute;
	top:0;
	width: 100%;
	left: 0;
	-moz-transform: translateY(0px);
	-webkit-transform: translateY(0px);
	-o-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.alert.alert-hidden{
	-moz-transform: translateY(-150px);
	-webkit-transform: translateY(-150px);
	-o-transform: translateY(-150px);
	-ms-transform: translateY(-150px);
	transform: translateY(-150px);
	
}