.forecast-matches .dropdown {
  width: 100%;
  margin: 1.5rem 0;
}

.forecast-matches .dropdown button.btn.btn-secondary {
  width: 100%;
  font-weight: 500;
  background: #2196F3;
  border: 0;
}

.forecast-matches .dropdown button .dropdown-menu button {
  width: 50%;
  font-weight: 500;
  background: transparent;
  border: 0;
}

.forecast-matches .dropdown.show .dropdown-menu.show{
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  top: 6.2rem!important;
  width: 100%;
}
/* 
.match-filled {
  animation: loader 8s ease infinite;
  display: block;
  background: red;
  height: 2px;
}
 */