.profile-avatar {
    width: 100%;
    display: flex;
    justify-content: center;
    height:100px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.profile-avatar .profile-avatar__randomize {
    position: absolute;
    width: 40px;
    height: 40px;
    text-align: center;
    bottom: -10px;
    background: #607D8B;
    border-radius: 50%;
    padding: 0.5rem;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.profile-avatar:active img {
    opacity: 0.6
}

.profile-avatar:active .profile-avatar__randomize {
    -moz-transform: scale(.8);
    -webkit-transform: scale(.8);
    -o-transform: scale(.8);
    -ms-transform: scale(.8);
    transform: scale(.8);
}

.profile-form .form-group label, .profile-chnpassword .form-group label {
    font-size: .8rem;
    font-weight: 500;
    color: #ffffff61;
}

.profile-form .form-group input, .profile-chnpassword .form-group input {
    background: transparent;
    border: 0;
    border-bottom: 3px solid rgb(70, 70, 70);
    border-radius: 0;
    color: #fff;
    font-weight: 500;
}

.profile-form .form-group input:active, .profile-form .form-group input:focus, .profile-chnpassword .form-group input:active, .profile-chnpassword .form-group input:focus {
    outline: 0;
    box-shadow: none;
    border-bottom-color: #234f9c;
}

.profile-none {
    margin: 1rem 0;
    background: transparent;
    border: 0;
    font-weight: 500;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-size: .8rem;
}

