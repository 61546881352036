.groupsearch-container {
  margin-bottom: 1rem;
}

.groupsearch-container .input-group {
  margin-bottom: 1rem;
}

.groupsearch-container .input-group input {
  background: transparent;
  border: 0;
  border-bottom: 3px solid #234f9c;
  border-radius: 0;
  color: #fff;
  font-weight: 500;
}

.groupsearch-container *:focus {
  outline: none;
  box-shadow: none
}

.groupsearch-container .input-group button {
  background: transparent;
  border: 0;
}

.groupsearch-container .input-group button svg {
  width: 30px;
}

.groupsearch-container .input-group button:active, .groupsearch-container .input-group button:focus {
  background-color: transparent!important;
  border: 0;
  opacity: 0.6;
  box-shadow: none;
}

.groupsearch-info {
  text-align: center;
  color: #ffffff96;
  font-size: .9rem;
  font-weight: 500;
}

.groupsearch-container .groupsearch-searchicon:not(:disabled):not(.disabled):active:focus {
  outline: 0;
  box-shadow: none;
}