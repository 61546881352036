.widget-matchpredict .widget-matchpredict__date span {
  font-size: .8rem;
  font-weight: 500;
  padding: .2rem 0;
  margin-bottom: .5rem;
  text-align: center;
  opacity: .6;
  display: block;
}

.widget-default.widget-matchpredict .row {
  width: 100%;
  margin-left: 0;
  align-items: center;
  padding: .5rem 0;
}

.widget-default.widget-matchpredict .widget-matchpredict__result span{
  font-size: .9rem;
  font-weight: 500;
  padding: .2rem 0;
  margin-bottom: .5rem;
  text-align: center;
}

.widget-default.widget-matchpredict .widget-matchpredict__guess {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0;
  align-items: center;
  flex-direction:column
}

.widget-default.widget-matchpredict .widget-matchpredict__guess .user-input{
  display:flex;
  margin-bottom: 0.8rem;
}

.widget-default.widget-matchpredict .widget-matchpredict__guess input {
  padding: 0;
  text-align: center;
  font-weight: 700;
  border-radius: 2px;
  color: #fff;
  background-color: #303030;
  border: 1px solid #676767;
}

.widget-default.widget-matchpredict .widget-matchpredict__guess input:first-child {
  margin-right: 1rem;
}

.widget-default.widget-matchpredict .widget-matchpredict__guess input:last-child {
  margin-left: 1rem;
}

.widget-default.widget-matchpredict .widget-matchpredict__local, .widget-default.widget-matchpredict .widget-matchpredict__guess, .widget-default.widget-matchpredict .widget-matchpredict__away {
  text-align: center;
}

.widget-default.widget-matchpredict img {
  width: 32px;
}

.widget-default.widget-matchpredict h6 {
  font-weight: bold;
  font-size: .9rem;
  margin-top: .5rem;
  display: block;
}

.widget-default.widget-matchpredict small {
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
}

.widget-default.widget-matchpredict span svg {
  margin-bottom: 3px
}

.widget-default.widget-matchpredict .widget-matchpredict__points{
  position: absolute;
  display: flex;
  right: -8px;
  border-radius: 5rem;
  white-space: nowrap;
  padding: 2px 5px;
  width: 40px;
  height: 20px;
  background: #2196F3;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: .7rem;
  top: -6px;
}

.widget-default.widget-matchpredict .widget-matchpredict__live {
  position: absolute;
  display: flex;
  right: -8px;
  border-radius: 5rem;
  white-space: nowrap;
  padding: 2px 5px;
  width: 75px;
  height: 20px;
  background: #3a3a3a;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: .7rem;
  top: -6px;
}

.widget-matchpredict__live span{
  background: red;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  margin-right: 0.5rem;
  transition: all .2s ease-in-out;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.widget-default.widget-matchpredict.success .widget-matchpredict__points {
  background-color: limegreen;
}

.widget-default.widget-matchpredict.unsuccessfully .widget-matchpredict__points {
  background-color: red;
}

.widget-default.widget-matchpredict.no_played .widget-matchpredict__points{
  display: none;
  
}

.widget-matchpredict__help {
  background: rgba(0, 0, 0, .1);
  padding: .5rem .2rem;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  background-color: #485461;
  background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
  font-weight: 500;
  font-size: .9rem;
}

.widget-matchpredict__deathmatch {
  background: rgba(0, 0, 0, .1);
  padding: .5rem .2rem;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  background-color: rgb(72,84,97);
  background-image: linear-gradient(315deg, #485461 0%, #d60b0b 74%,#485461 100%);
  background: linear-gradient(90deg, rgba(72,84,97,1) 0%, rgba(214,11,11,1) 50%, rgba(72,84,97,1) 100%);
  font-weight: 500;
  font-size: .9rem;
}

.widget-matchpredict__feedback {
  height: 2px;
  background: #4CAF50;
  width: 0%;
  display: block;
  animation: loader 1s ease infinite;
}


@-webkit-keyframes loader {
  0% {
    width: 0;
  }
  20% {
    width: 10%;
  }
  25% {
    width: 24%;
  }
  43% {
    width: 41%;
  }
  56% {
    width: 50%;
  }
  66% {
    width: 52%;
  }
  71% {
    width: 60%;
  }
  75% {
    width: 76%;
  }
  94% {
    width: 86%;
  }
  100% {
    width: 100%;
  }
}

@keyframes loader {
  0% {
    width: 0;
  }
  20% {
    width: 10%;
  }
  25% {
    width: 24%;
  }
  43% {
    width: 41%;
  }
  56% {
    width: 50%;
  }
  66% {
    width: 52%;
  }
  71% {
    width: 60%;
  }
  75% {
    width: 76%;
  }
  94% {
    width: 86%;
  }
  100% {
    width: 100%;
  }
}


@keyframes pulse {
  from {
    transform: scale(1, 1);
    opacity: 0.75;
  }
  to {
    transform: scale(1.25, 1.25);
    opacity: 0;
  }
}