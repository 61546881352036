.footer,
.grupos.busqueda .footer,
.grupos.crear .footer{
    height: 100px;
    bottom: 0;
    position: relative;
}
/*FIXEAR ESTO */
.pronosticos.Superliga .footer,
.grupos .footer,
.pronosticos.Paraguay .footer{
    position: relative;
}
.footer img{
    width: 80px;
    display: block;
    margin: 3rem auto 0.5rem;
}
.footer .footer-bg{
    width: 100%;
    display: block;
    margin: 0 auto;
    left: 0;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center -130px;
    opacity: .8;
}

.footer span{
    font-weight: bold;
    font-size: .78rem;
    text-align: center;
    display: block;
}

.ingresar .footer,
.wizard .footer{
    display:none;
}