.menu-items {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
}

.menu-item {
  width: 45%;
  height: 9rem;
  margin: .5rem;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.menu-item-banner {
  width: 100%;
  height: 9rem;
  margin: .5rem;
  border: 1px solid;
  border-radius: 10px;
  position: relative;
  overflow: auto;
}

.menu-item h2 {
  font-size: 1rem;
  z-index: 1;
  padding: 0.5rem 1rem;
  width: 100%;
}

.menu-item .menu-item__container {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  border: 0;
  background-color: #48546182;
  background-image: linear-gradient(315deg, #485461 0%, #28313b7a 74%);
}

.menu_ayuda, .menu_perfil, .menu_pronostico, .menu_grupo {
  background-color: #2f2f2f;
  background-repeat: no-repeat;
}

.menu_destacado {
  background-color: #2f2f2f;
  border: 2px solid rgba(97, 97, 97, .15);
  background-repeat: no-repeat;
  height: auto;
  width: 94%;
  border: 0;
  background: none;
}

.menu_destacado img {
  display: block;
  width: 100%;
}

.menu-item .menu-item__background {
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item .menu-item__background span {
  width: 270%;
  height: 230%;
  position: absolute;
  content: '';
  background-repeat: no-repeat;
  filter: grayscale(1);
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
}

.menu_pronostico .menu-item__background span {
  background-image: url(https://d3qiofd49j60uf.cloudfront.net/fpd_prode/general/menu/pronosticos-background-image.jpg);
  background-position: 30% 45%;
  -webkit-animation: animatedBackgroundOne 26s ease-in-out infinite alternate;
  -moz-animation: animatedBackgroundOne 26s ease-in-out infinite alternate;
  -ms-animation: animatedBackgroundOne 26s ease-in-out infinite alternate;
  -o-animation: animatedBackgroundOne 26s ease-in-out infinite alternate;
  animation: animatedBackgroundOne 26s ease-in-out infinite alternate;
}

.menu_grupo .menu-item__background span {
  background-image: url(https://d3qiofd49j60uf.cloudfront.net/fpd_prode/general/menu/grupos-background-image.jpg);
  -webkit-animation: animatedBackgroundTwo 23s ease-in-out infinite alternate;
  -moz-animation: animatedBackgroundTwo 23s ease-in-out infinite alternate;
  -ms-animation: animatedBackgroundTwo 23s ease-in-out infinite alternate;
  -o-animation: animatedBackgroundTwo 23s ease-in-out infinite alternate;
  animation: animatedBackgroundTwo 23s ease-in-out infinite alternate;
}

.menu_perfil .menu-item__background span {
  background-image: url(https://d3qiofd49j60uf.cloudfront.net/fpd_prode/general/menu/perfil-background-image.jpg);
  -webkit-animation: animatedBackgroundThree 25s ease-in-out infinite alternate;
  -moz-animation: animatedBackgroundThree 25s ease-in-out infinite alternate;
  -ms-animation: animatedBackgroundThree 25s ease-in-out infinite alternate;
  -o-animation: animatedBackgroundThree 25s ease-in-out infinite alternate;
  animation: animatedBackgroundThree 25s ease-in-out infinite alternate;
}

.menu_ayuda .menu-item__background span {
  background-image: url(https://d3qiofd49j60uf.cloudfront.net/fpd_prode/general/menu/ayuda-background-image.jpg);
  -webkit-animation: animatedBackgroundFour 32s ease-in-out infinite alternate;
  -moz-animation: animatedBackgroundFour 32s ease-in-out infinite alternate;
  -ms-animation: animatedBackgroundFour 32s ease-in-out infinite alternate;
  -o-animation: animatedBackgroundFour 32s ease-in-out infinite alternate;
  animation: animatedBackgroundFour 32s ease-in-out infinite alternate;
}

/* ANIMACION 1 */

@keyframes animatedBackgroundOne {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.95) translate(-30px, -30px);
  }
}

@-webkit-keyframes animatedBackgroundOne {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.95) translate(-30px, -30px);
  }
}

@-moz-keyframes animatedBackgroundOne {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.95) translate(-30px, -30px);
  }
}

@-o-keyframes animatedBackgroundOne {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.95) translate(-30px, -30px);
  }
}

/* ANIMACION 1 */

/* ANIMACION 2 */

@keyframes animatedBackgroundTwo {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(1.1) translate(-120px, -40px);
  }
}

@-webkit-keyframes animatedBackgroundTwo {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(1.1) translate(-120px, -40px);
  }
}

@-moz-keyframes animatedBackgroundTwo {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(1.1) translate(-120px, -40px);
  }
}

@-o-keyframes animatedBackgroundTwo {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(1.1) translate(-120px, -40px);
  }
}

/* ANIMACION 2 */

/* ANIMACION 3 */

@keyframes animatedBackgroundThree {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.958) translate(30px, 80px);
  }
}

@-webkit-keyframes animatedBackgroundThree {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.958) translate(30px, 80px);
  }
}

@-moz-keyframes animatedBackgroundThree {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.958) translate(30px, 80px);
  }
}

@-o-keyframes animatedBackgroundThree {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.958) translate(30px, 80px);
  }
}

/* ANIMACION 3 */

/* ANIMACION 4  */

@keyframes animatedBackgroundFour {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.6) translate(-70px, -10px);
  }
}

@-webkit-keyframes animatedBackgroundFour {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.6) translate(-70px, -10px);
  }
}

@-moz-keyframes animatedBackgroundFour {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.6) translate(-70px, -10px);
  }
}

@-o-keyframes animatedBackgroundFour {
  from {
    transform: scale(1) translate(0,0);
  }
  to {
    transform: scale(.6) translate(-70px, -10px);
  }
}

/* ANIMACION 4  */