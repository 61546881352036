/* TEMPORAL */
/* TEMPORAL */
.ranking .nav-tabs{
  justify-content: flex-start;
  margin-bottom:1rem
}
.ranking-content__stage .dropdown{
  margin-bottom:1rem;
}
.ranking-content__stage .dropdown button{
  width: 100%;
  font-weight: 500;
  background: #2196F3;
  border: 0;
}
.ranking-content__stage .dropdown.show .dropdown-menu.show .dropdown-item {
  width: 50%;
  font-weight: 500;
  background: transparent!important;
  border: 0;
}


.ranking-content__stage .dropdown.show .dropdown-menu.show{
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  top: 6.2rem!important;
  width: 100%;
}
/* TEMPORAL */
/* TEMPORAL */

.podium {
  align-items: flex-end;
}

.podium__position {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.podium__avatar {
  border-radius: 50%;
  height: 90px;
  overflow: hidden;
  padding: 0.4rem 0 0 0;
  width: 90px;
  background-image: url(/header-background.png);
  background-size: cover;
  background-color: #151515;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.podium__position.chart-position2 .podium__avatar {
  height: 70px;
  width: 70px
}

.podium__position.chart-position2 .podium__chart {
  height: 70px;
  bottom: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f5f6f6+0,dbdce2+21,b8bac6+49,dddfe3+80,f5f6f6+100;Grey+Pipe */
  background: rgb(245, 246, 246);
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, rgba(245, 246, 246, 1) 0%, rgba(219, 220, 226, 1) 21%, rgba(184, 186, 198, 1) 49%, rgba(221, 223, 227, 1) 80%, rgba(245, 246, 246, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, rgba(245, 246, 246, 1) 0%, rgba(219, 220, 226, 1) 21%, rgba(184, 186, 198, 1) 49%, rgba(221, 223, 227, 1) 80%, rgba(245, 246, 246, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(245, 246, 246, 1) 0%, rgba(219, 220, 226, 1) 21%, rgba(184, 186, 198, 1) 49%, rgba(221, 223, 227, 1) 80%, rgba(245, 246, 246, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f6f6', endColorstr='#f5f6f6', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.podium__position.chart-position1 .podium__chart {
  height: 90px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
}

.podium__position.chart-position3 .podium__chart {
  height: 50px;
  background-color: #772f1a;
  background-image: linear-gradient(315deg, #772f1a 0%, #f2a65a 74%);
}

.podium__position.chart-position3 .podium__avatar {
  height: 50px;
  width: 50px
}

.podium__position svg, .podium__position img, .podium__position svg, .podium__position span {
  display: block;
  margin: 0 auto;
}

.podium__points {
  font-weight: 500;
  font-size: .9rem;
}

.podium__name {
  font-weight: 500;
  font-size: 1.2rem;
}

.podium__chart {
  align-items: flex-end;
  display: grid;
  text-align: center;
  width: 100%;
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #7f8c8d 74%);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 0.2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.podium-list__group {
  margin: 0;
  padding: 0;
}

.ranking-content__global .podium__position__winner, .ranking-content__stage .podium__position__winner {
  display: none;
}

/* Esta es la clase de estilo para la corona */

.ranking-content__global.finished .chart-position1 .podium__position__winner {
  color: rgb(255, 196, 0);
  display: block;
  font-size: 2rem;
  margin-bottom: 5px;
}

.podium-list__group .list-group-item {
  align-items: center;
  padding: .5rem 1.5rem;
}

.podium-list__group .list-group-item:first-child {
  background: transparent;
}

.podium-list__group .list-group-item, .podium-list__group .list-group-item .list-group-item__user {
  text-align: left;
  border: 0;
  border-radius: 0;
}

.podium-list__group .list-group-item .list-group-item__user {
  padding: 0 0.3rem
}

.podium-list__group .list-group-item .list-group-item__user img {
  width: 40px;
  height: 40px;
  background: #525252;
  border-radius: 50%;
  padding: 0.2rem;
  margin-right: 0.5rem
}

.podium-list__group .list-group-item:nth-child(odd) {
  background: rgba(35, 35, 35, 0.41)
}

.podium-list__container {
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  padding: 1rem 0;
  border-radius: 5px;
  margin: 0 .7rem;
}

/* Lista de puntos por grupos */
.podium-list__group {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.podium-list__group li:nth-child(odd) {
  background: #00000042
}

.podium-list__group li {
  padding: .3rem 0;
  background: #00000073;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 32px;
}

.podium-list__group li:first-child {
  background: #000000;
  text-align: center;
  font-weight: 500;
  padding: 0.5rem 0;
  font-size: .9rem;
}

.podium-list__group li:first-child div:nth-child(2) {
  text-align: left;
}

.podium-list__group li:nth-child(2) div:first-child {
  font-weight: 500;
}

.podium-list__group .podium__user {
  display: flex;
  align-items: center;
}

.podium-list__group .podium__user span {
  width: 100%;
  text-align: left;
  font-weight: 400;
}


.podium-list__group .podium__user img {
  width: 32px;
  background: rgba(255, 255, 255, 0.23);
  margin-right: 0.5rem;
  border-radius: 50%;
  padding: 0.2rem;
  display: none;
}

@media (min-width: 768px) {
  .podium-list__group .podium__user img {
    display: block
  }
}