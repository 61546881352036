.grupos .groups-container {
    min-height: 90vh;
}
.groups-container {
    margin-top: 2rem;
    overflow: hidden;
}

.search-groups {
    margin-bottom: 0.5rem;
    border: 0;
    overflow: hidden;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: #2a2a72;
    background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
    width:45%;
}

.search-groups .card-body {
    padding: 0;
    border: 0;
    border-radius: 5px;
    text-align: center;
    height: 40px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: .9rem;
}

.search-groups .card-body img {
    display:none;
    width: 80px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.groups-search-create{
    display:flex;
    justify-content: space-around;
}