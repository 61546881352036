.ayuda .faq {
    min-height: 100vh;
}

.faq {
    margin-top: 2rem;
}

.faq h5 {
    font-size: 1.4rem;
}

.faq button {
    margin-top: 1rem;
    font-size: 1rem;
    background: #607D8B;
    border: 0;
    padding: 1rem;
    border-radius: 2px;
    text-align: center;
    height: auto;
}