.navbar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.navbar_goback {
  width: 20%;
  position: absolute;
  left: 0;
  padding: 1rem;
  text-align: left;
}

.navbar_section {
  text-align: center;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem;
}

.navbar_goback svg {
  width: 15px;
  margin-left: 0.5rem;
  fill: #fff;
}
