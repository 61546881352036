.cambio__clave .profile-container{
    min-height: 65vh;
}
.profile-container {
    margin-top: 2rem;
}

.profile-password,
.profile-logout{
    width: 100%;
    border: 0;
    margin: 1.2rem 0;
    display: flex;
    align-items: center;
    background-color: #292929;
}

.profile-container .profile-button__description{
    flex: 1;
    align-items: flex-start;
    text-align: left;
    padding: 0.5rem;
}

.profile-container .profile-button__description span{
    text-align: left;
    margin: 0;
}

.profile-save {
    background: #4CAF50;
    border: 0;
    border-radius: 2px;
    width: 100%;
}

.profile-avatar {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.profile-img-avatar {
    border-radius: .25rem;
    width: 100px;
    background-size: cover;
    background-image: url(/header-background.png);
    background-position: center;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
}

.profile-logout {
    border-top:3px solid #F44336;
}

.profile-container input {
    border-radius: 2px;
}

.profile-container .profile-form input[type=email] {
    background: transparent;
    color: #ffffff40;
    border-color: #464646;
}

.profile-form__locked{
    position:relative;
}

.profile-form__locked svg{
    bottom: .7rem;
    left: 0;
    position: absolute;
    fill: #464646;
    font-weight: bold;
}
.profile-container .profile-form .profile-form__locked input[type=email]{
    padding-left: 1.5rem;
}
