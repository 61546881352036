.widget-streakclash h3 {
  font-size: 1rem;
  margin: 0;
  padding: .8rem 0;
  text-align: center;
  display: block;
}

.widget-streakclash ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.widget-streakclash ul li:nth-child(odd){
  background:#00000042
}

.widget-streakclash ul li{
  padding-bottom: .5rem;
  background: #00000073;
}

.widget-streakclash .widget-streakclash__results {
  display: flex;
}

.widget-streakclash .widget-streakclash__results img {
  width: 28px;
}

.widget-streakclash .widget-streakclash__results .match-home {
  text-align: right;
  flex:1;
  padding:0 0.5rem;
}

.widget-streakclash .widget-streakclash__results .match-result {
  text-align: center;
}

.widget-streakclash .widget-streakclash__results .match-result span{
  background: #00000070;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-weight: 500;
}

.widget-streakclash .widget-streakclash__results .match-away {
  flex:1;
  text-align: left;
  padding:0 0.5rem;
}

.widget-streakclash .widget-streakclash__results .match-away span,
.widget-streakclash .widget-streakclash__results .match-home span {
  font-weight: 500;
  font-size: .8rem;
  margin: 0 0.2rem;
}

.widget-streakclash .widget-streakclash__meta {
  display: flex;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #cccccc26;
  border-top: 1px solid #cccccc26;
}

.widget-streakclash .widget-streakclash__meta div:last-child{
  text-align: right;
}

.widget-streakclash .widget-streakclash__meta span {
  font-size: .8rem;
  text-align: center;
  font-weight: 500;
  opacity: .5;
}