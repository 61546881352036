.widget-counter h6 {
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 0 0.2rem 0;
  margin: 0;
}

.widget-counter ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 0 0.2rem 0;
}

.widget-counter ul li {
  border: 2px solid rgb(56, 136, 255);
  border-radius: 5px;
  margin: 0.5rem;
  padding: 0.5rem;
  width: 65px;
  text-align: center;
}

.widget-counter ul li span {
  font-size: 1.3rem;
}

/* .counter {
  color: #fff;
  text-align: center;
  margin: 20px;
}

.counter h3 {
  font-weight: 500;
  margin:0;
  font-size: 1rem;
}

.counter ul {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.counter ul li {
  margin:0 0.2rem;
}
.counter ul li span {
  font-weight: bold;
  font-size: 1.2rem;
} */