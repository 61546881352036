.wizard-container {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    overflow-x: hidden;
}

.wizard-container .choice {
    width: 80%;
    border-radius: 5px;
    box-shadow: 0 5px 20px 0 rgb(6, 7, 12);
    height: 90px;
    margin: 2rem auto;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 1rem;
    position: relative;
    background-image: linear-gradient(50deg, #2196F3 0%, #224d77 100%);
}

.wizard-container .wizard-description{
    text-align: center;
    font-weight: 500;
    padding: 0 1rem;
}
.wizard-container .choice img {
    width: 110px;
    left: 10px;
    position: absolute;
    bottom: -50px;
}

.wizard-container .choice p {
    color: #fff;
    margin: 0;
    margin-right: 1rem;
    font-weight: 500;
}

.wizard-step .tournament-create__form input {
    border-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border: 0;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    background: transparent;
    border: 0;
    border-bottom: 3px solid #234f9c;
    border-radius: 0;
    color: #fff;
    font-weight: 500;
}

.group-success {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}

.group-description {
    text-align: center;
    font-size: .9rem;
}

.wizard-step input:focus, .wizard-step button:focus {
    outline: none;
    box-shadow: none
}

.wizard-step .input-group button {
    background: transparent;
    border: 0;
}

.wizard-step .input-group input {
    background: transparent;
    border: 0;
    border-bottom: 3px solid #234f9c;
    border-radius: 0;
    color: #fff;
    font-weight: 500;
}

.tournament-card__button {
    background: transparent;
    border: 0;
    border-radius: 0;
    width: auto;
    font-weight: 500;
    font-size: 1rem;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}

.tournament-card__button-final {
    background: #2196F3;
    width: auto;
    border-radius: 2px;
}

.tournament-card__button:active,
.tournament-card__button:hover,
.tournament-card__button:focus {
    background: transparent!important;
    outline:0!important;
    box-shadow:none!important;
}

.wizard-step .col-sm-4.tournament_19, .wizard-step .col-sm-4.tournament_13 {
    margin-bottom: 2rem;
}

.wizard-step .is-selected {
    border: 1px solid #28a745;
    border-radius: 5px;
}
.wizard-step .alert{
    /* position: fixed; */
    top: 0;
    width: 100%;
    color: #fff;
    left: 0;
    background: #F44336;
    font-weight: 500;
    border: 0;
}
.wizard-step .alert.alert-success{
    background: transparent;
    text-align: center;
    color: #4caf50;
}

.wizard-step .demo_wizard{
    width: 100%;
    margin-bottom: 1rem;
}

.wizard-step .group-created{
    text-align:center;
}