.card_container{
  margin: 5px;
  width: 310px;
  padding: 10px;
  border: 1px solid black;
}




.empty {
  display:none;
}

.gridlist_container h6.invites-pending{
  margin-bottom: 0.5rem;
}

.grupos .swiper-container{
  overflow: visible;
}

.user-news .card_container{
  border-radius:5px;
  border:0;
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  box-shadow:0 5px 5px rgba(0,0,0,0.2);
  display:flex;
  justify-content: space-between;
  padding:1.3rem 0.5rem;
  width:290px;
}

.user-news .flickity-enabled,
.user-news .flickity-viewport{
  overflow: visible;
}

.user-news .card_container .card_title p{
  margin:0;
  font-weight: 500;
}

.user-news .card_container .card_title span{
  font-size: 0.9rem;
}

.user-news .card_container .card_title span strong{
  color: #009efd;
}

.user-news .card_container .card_btn_container button{
  text-transform: capitalize;
  font-size: .9rem;
  border:0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin: 0 0.5rem;
}


.user-news .card_container .card_btn_container{
  display:flex;
  }