.tournament-card {
  border: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 2px;
  background-color: transparent
}

/* #19 .tournament-card{
  background-color:red;
} */

.tournament-card img {
  width: 90px;
}

.tournament-card h2 {
  white-space: nowrap;
  font-size: 90%;
}

.tournament-create__tournament .flickity-viewport, .tournament-create__tournament .flickity-enabled {
  overflow: visible;
}

.pronosticos .pronosticos-list {
  min-height: 65vh;
  margin-top:1rem;
}

.pronosticos-list h6 {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.pronosticos-list button {
  border: 0;
  border-radius: 2px;
  height: 80px;
  font-weight: 500;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  opacity: .3;
  font-size: 1rem;
  background-color: #5a6268;
  border-color: #545b62;
}

.pronosticos-list button.tournament_ARG:focus, .pronosticos-list button.tournament_ARG:hover, .pronosticos-list button.tournament_ARG:active {
  opacity: 1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#450070+0,8400ad+100 */
  background: rgb(69, 0, 112);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(69, 0, 112, 1) 0%, rgb(37, 29, 39) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(69, 0, 112, 1) 0%, rgb(37, 29, 39) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(69, 0, 112, 1) 0%, rgb(37, 29, 39) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#450070', endColorstr='#8400ad', GradientType=0);
  /* IE6-9 */
  color: #fff;
  box-shadow: none;
}

.pronosticos-list button.tournament_PAR:focus, .pronosticos-list button.tournament_PAR:active, .pronosticos-list button.tournament_PAR:hover {
  box-shadow: none;
  opacity: 1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#007bf7+0,0056ad+100 */
  background: rgb(0, 123, 247);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(0, 123, 247, 1) 0%, rgba(0, 86, 173, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 123, 247, 1) 0%, rgba(0, 86, 173, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 123, 247, 1) 0%, rgba(0, 86, 173, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007bf7', endColorstr='#0056ad', GradientType=0);
  /* IE6-9 */
  color: #fff;
}

.pronosticos-list img.tournament-icon {
  width: 50px;
}

.pronosticos .pronosticos-list .tournament_PAR {
  opacity: 1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#007bf7+0,0056ad+100 */
  background: rgb(0, 123, 247);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(0, 123, 247, 1) 0%, rgba(0, 86, 173, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 123, 247, 1) 0%, rgba(0, 86, 173, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 123, 247, 1) 0%, rgba(0, 86, 173, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007bf7', endColorstr='#0056ad', GradientType=0);
  /* IE6-9 */
  color: #fff;
}

.pronosticos .pronosticos-list .tournament_ARG {
  opacity: 1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#450070+0,8400ad+100 */
  background: rgb(69, 0, 112);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(69, 0, 112, 1) 0%, rgb(37, 29, 39) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(69, 0, 112, 1) 0%, rgb(37, 29, 39) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(69, 0, 112, 1) 0%, rgb(37, 29, 39) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#450070', endColorstr='#8400ad', GradientType=0);
  /* IE6-9 */
  color: #fff;
}