.grupos .ranking {
    min-height: 65vh;
}

.component-ranking__leave_groups {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border: 0;
    border-radius: 5px;
    background-color: #F44336;
    width: 45%;
    margin: 0 auto;
}

.component-ranking__leave_groups .card-body {
    padding: 0;
    border: 0;
    border-radius: 5px;
    text-align: center;
    height: 40px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: .9rem;
}
.container.ranking {
    width: 100%;
    max-width: none;
    margin-bottom: 2rem;
}

.span-info.leave_groups-info{
    color: #fff;
    border-top: 2px solid #ffffff21;
    margin: 0.5rem;
    padding: 1rem;
}