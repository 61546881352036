.widget-default.widget-teamshistory {
  font-size: 1rem;
  padding: 0;
  text-align: center;
  display: block;
  overflow: hidden;
  position: relative;
}

.widget-default.widget-teamshistory.premium-demo:before {
  -webkit-transform-origin: center;
  transform-origin: center;
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .3;
  top: 0;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  left: 0;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  animation-name: gradient_variation;
  animation-duration: 5s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

.widget-teamshistory__container {
  background: #202020;
  margin: 0.1rem;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.widget-teamshistory .widget-streak__special-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.widget-teamshistory .widget-streak__special-title h3 {
  font-size: 1rem;
  padding: .8rem 0;
  margin: 0;
  text-align: left;
  display: block;
}

.widget-teamshistory .widget-streak__special-title h3 img {
  width: 20px;
  margin: 0 .5rem;
}

.widget-teamshistory .widget-teamhistory__indicator {
  background: #ff98007a;
  margin: 0 0.5rem;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  text-align: center;
}

.widget-teamshistory .widget-teamhistory__indicator svg {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

.widget-teamshistory.premium-demo .widget-teamshistory__data {
  filter: blur(12px);
}

.widget-teamshistory .widget-teamshistory__data ul {
  list-style: none;
  padding: 1rem;
  margin: 0;
}

.widget-teamshistory .widget-teamshistory__data ul svg {
  margin-top: 0.1rem;
}

.widget-teamshistory .widget-teamshistory__data ul li {
  text-align: left;
  margin: 0.5rem 0;
  display: flex;
}

.widget-teamshistory .widget-teamshistory__data ul li span {
  flex: 1;
  padding: 0 0.5rem;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.widget-premium__notice {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.widget-premium__notice p{
  font-weight: 500;
  padding:1rem;
}

.widget-premium__notice button{
  font-weight: 500;
  background: #2196F3;
  border:0;
}

@keyframes gradient_variation {
  from {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}