.widget-forecastgroup .widget-forecastgroup__date span {
  font-size: .8rem;
  font-weight: 500;
  padding: .2rem 0;
  margin-bottom: .5rem;
  text-align: center;
  opacity: 1;
  display: block;
}

.widget-default.widget-forecastgroup {
  background: url('https://d3qiofd49j60uf.cloudfront.net/fpd_prode/general/default-stadium.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.widget-default.widget-forecastgroup:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
}

.widget-default.widget-forecastgroup .row {
  width: 100%;
  margin-left: 0;
  align-items: center;
  padding: .5rem 0;
}

.widget-default.widget-forecastgroup .widget-forecastgroup__result span {
  font-size: .9rem;
  font-weight: 500;
  padding: .2rem 0;
  margin-bottom: .5rem;
  text-align: center;
}

.widget-default.widget-forecastgroup .widget-forecastgroup__guess {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0;
  align-items: center;
  flex-direction: column
}

.widget-default.widget-forecastgroup .widget-forecastgroup__guess .user-input {
  display: flex;
  margin-bottom: 0.8rem;
}

.widget-default.widget-forecastgroup .widget-forecastgroup__guess input {
  padding: 0;
  text-align: center;
  font-weight: 700;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #676767;
  border: 0;
  background: transparent
}

.widget-default.widget-forecastgroup .widget-forecastgroup__guess input:first-child {
  margin-right: 1rem;
}

.widget-default.widget-forecastgroup .widget-forecastgroup__guess input:last-child {
  margin-left: 1rem;
}

.widget-default.widget-forecastgroup .widget-forecastgroup__local, .widget-default.widget-forecastgroup .widget-forecastgroup__guess, .widget-default.widget-forecastgroup .widget-forecastgroup__away {
  text-align: center;
}

.widget-default.widget-forecastgroup img {
  width: 32px;
}

.widget-default.widget-forecastgroup h6 {
  font-weight: bold;
  font-size: .9rem;
  margin-top: .5rem;
  display: block;
}

.widget-default.widget-forecastgroup small {
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
}

.widget-default.widget-forecastgroup span svg {
  margin-bottom: 3px
}

.widget-default.widget-forecastgroup .widget-forecastgroup__points {
  position: absolute;
  display: flex;
  right: -8px;
  border-radius: 5rem;
  white-space: nowrap;
  padding: 2px 5px;
  width: 40px;
  height: 20px;
  background: #2196F3;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: .7rem;
  top: -6px;
}

.widget-default.widget-forecastgroup.success .widget-forecastgroup__points {
  background-color: limegreen;
}

.widget-default.widget-forecastgroup.unsuccessfully .widget-forecastgroup__points {
  background-color: red;
}

.widget-default.widget-forecastgroup.no_played .widget-forecastgroup__points {
  display: none;
}

/* Lista de puntos por grupos */

.widget-default.widget-listgroups h3 {
  font-size: 1rem;
  margin: 0 0.5rem;
  padding: .5rem 0;
  text-align: left;
  display: block;
}

.widget-default.widget-listgroups h3 img {
  width: 32px;
  border-radius: 50%;
  margin-right: 0.4rem;
}

.widget-default.widget-listgroups ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.widget-default.widget-listgroups ul li:nth-child(odd) {
  background: #00000042
}

.widget-default.widget-listgroups ul li {
  padding: .3rem 0;
  background: #00000073;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 32px;
}

.widget-default.widget-listgroups ul li:first-child {
  background: #000000;
  text-align: center;
  font-weight: 500;
  padding: 0.5rem 0;
  font-size: .9rem;
}

.widget-default.widget-listgroups ul li:first-child div:nth-child(2) {
  text-align: left;
}

.widget-default.widget-listgroups ul li:nth-child(2) div:first-child {
  font-weight: 500;
}

.widget-default.widget-listgroups .widget-listgroups__user {
  display: flex;
  align-items: center;
}

.widget-default.widget-listgroups .widget-listgroups__user span {
  width: 100%;
  text-align: left;
  font-weight: 400;
}

.widget-default.widget-listgroups .widget-listgroups__user.class_user_loged span{
  color: #2196F3;
  font-weight: 500;
}

.widget-default.widget-listgroups .widget-listgroups__user img {
  width: 32px;
  background: rgba(255, 255, 255, 0.23);
  margin-right: 0.5rem;
  border-radius: 50%;
  padding: 0.2rem;
  display: none;
}

@media (min-width: 768px) {
  .widget-default.widget-listgroups .widget-listgroups__user img {
    display: block
  }
}