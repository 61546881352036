.resultados-container .flickity-viewport:focus, .resultados-container .flickity-viewport, .flickity-enabled, .flickity-enabled:focus {
  outline: none;
  overflow: hidden;
}

.swiper-slide.stats-widget {
  padding: .5rem;
  height: 95px;
  background-color: #2f2f2f;
  border: 2px solid #61616126;
  border-radius: 5px;
  margin: 0 0.2rem;
  color: #fff;
  width: 70%;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
}

.stats-widget:focus {
  outline: none;
}

.spinner-stats {
  position: relative !important;
  margin: 2rem;
}

.stats-widget__title p {
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
}

.stats-widget__legend p {
  margin: 0;
  font-weight: 500;
  font-size: .8rem;
  opacity: .7;
}

.stats-widget .stats-widget__values {
  display: flex;
  align-items: center;
}

.stats-widget .stats-widget__values h1 {
  margin: 0 0.5rem;
  font-size: 1.4rem;
}

.stats-widget .stats-widget__values span {
  width: 30px;
  height: 30px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.stats-container {
  padding:0 0.5rem;
  overflow: hidden;
}