body {
  font-family: "Rubik", "Helvetica Neue", sans-serif;
  background-image: linear-gradient(-50deg, #000000 0%, #323232 100%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
  color: #fff;
}
[role="main"]{
  min-height:100vh
}
.button {
  display: block;
  margin: 2rem auto;
  width: 200px;
  background-image: linear-gradient(-179deg, #4e5454 0%, #3e4343 100%);
  border: 0;
  border-radius: 5rem;
  padding: 0.5rem 2rem;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.button:active {
  opacity: 0.8
}

.button:focus {
  outline: 0;
}

.button-off {
  display: block;
  margin: 1rem auto;
  width: 200px;
  font-weight: normal;
  background: transparent;
  border: 0;
  border-radius: 5rem;
  padding: 0.5rem 2rem;
  text-align: center;
  color: #fff;
}

.header-brand {
  margin: 1rem 0;
}

.header-brand img {
  display: block;
  margin: 0 auto;
  width: 120px;
}

.span-info {
  color: #cccccc5c;
  text-align: center;
  display: block;
  font-size: .8rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.spinner-border {
  margin: 0 auto;
  display: block;
  border: .1em solid #03A9F4;
  border-right-color: #2a4861;
}

.widget-default{
  position: relative;
  border-radius: 5px;
  background: #1f1f1f;
  margin-bottom: 1rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
}