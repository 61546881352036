.disable_navbar{
    display:none;
}

.menu-header{
    margin:1rem;
}

.menu-header h1{
    margin:0;
    padding:0;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.menu-header h1 img{
    width: 30px;
    border-radius: 50%;
    background: #2e72af;
    margin-right: 0.5rem;
}